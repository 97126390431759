import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Process = makeShortcode("Process");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "remember",
      "style": {
        "position": "relative"
      }
    }}>{`Remember`}<a parentName="h1" {...{
        "href": "#remember",
        "aria-label": "remember permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`“Wisdom is knowing I am nothing,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Love is knowing I am everything,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`and between the two my life moves.”`}</em>{`  `}</p>
        <p>{`Nisargadatta Maharaj`}</p>
      </Box>
    </InfoBlock>
    <p><em parentName="p">{`All the words on this page were gathered by Marlon Barrios Solano, to whom we are deeply grateful`}</em>{`.`}</p>
    <p>{`We invite you to explore the four foundations of mindfulness with us. You need nothing to participate meaningfully in these sessions. No prior experience is required, nor do we promote any particular technique. We just sit together and delight in sharing time. Simply come along, and we can investigate together the nature of emptiness and enjoy the harmony of silence.`}</p>
    <p>{`These foundations can assist your investigation of the nature of awareness, suffering, and remembrance. In our sessions we will go on guided journies intended to help us rest gently in a more full awareness of our body and breath, our feeling tones, our heart-mind and expansive awareness.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The term `}<strong parentName="p">{`sati`}</strong>{` (Sanskrit: `}<em parentName="p">{`smṛti`}</em>{`), which is often translated as mindfulness, also means memory and recollection, and it is often used in that sense in the early discourses, which sometimes define sati as "the ability of calling to mind what has been done or said long ago." According to Sharf, in the Satipaṭṭhāna Sutta, the term sati means to remember the dharmas, which allows one to see the true nature of phenomena.`}</p>
    </blockquote>
    <h1 {...{
      "id": "record",
      "style": {
        "position": "relative"
      }
    }}>{`Record`}<a parentName="h1" {...{
        "href": "#record",
        "aria-label": "record permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We offer in this section a selection of audio clips of the guided meditations, along with some framing content to help you understand the ideas and context from which they come. These are not presented as universal truths, but as pointers to your own life and experience. Each meditation moves through one of the traditonal pillars of "sati". Beginning in the body, please join us for a slow stroll nowhere in particular.`}</p>
    <h1 {...{
      "id": "the-four-foundations",
      "style": {
        "position": "relative"
      }
    }}>{`The Four Foundations`}<a parentName="h1" {...{
        "href": "#the-four-foundations",
        "aria-label": "the four foundations permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`These foundations are traditionally known as `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Satipatthana"
      }}>{`Satipatthana`}</a>{` or The Four Establishments of Mindfulness.`}</p>
    <Process mdxType="Process">
      <p>{`mindfulness of the body (Pāli: `}<em parentName="p">{`kāyagatā-sati`}</em>{`; Sanskrit `}<em parentName="p">{`kāya-smṛti`}</em>{`),`}</p>
      <p>{`mindfulness of feelings (Pāli `}<em parentName="p"><a parentName="em" {...{
            "href": "https://en.wikipedia.org/wiki/Vedan%C4%81"
          }}>{`vedanā-sati`}</a></em>{`; Sanskrit `}<em parentName="p">{`vedanā-smṛti`}</em>{`),`}</p>
      <p>{`mindfulness of the mind (Pāli `}<em parentName="p"><a parentName="em" {...{
            "href": "https://en.wikipedia.org/wiki/Citta"
          }}>{`citta-sati`}</a></em>{`; Sanskrit `}<em parentName="p">{`citta-smṛti`}</em>{`)`}</p>
      <p>{`mindfulness of principles or phenomena (Pāli `}<em parentName="p"><a parentName="em" {...{
            "href": "https://en.wikipedia.org/wiki/Dhamm%C4%81"
          }}>{`dhammā-sati`}</a></em>{`; Sanskrit `}<em parentName="p">{`dharma-smṛti`}</em>{`).`}</p>
    </Process>
    <p>{`There are also some related notions which it may be worth reading about if this kind of inquiry interests you:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Vipassan%C4%81"
        }}>{`Vipassana`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Namarupa"
        }}>{`Namarupa`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Skandha"
        }}>{`Skandhas`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Anatt%C4%81"
        }}>{`Anatta`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Du%E1%B8%A5kha"
        }}>{`Dukkha`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Impermanence"
        }}>{`Anicca`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/%C5%9A%C5%ABnyat%C4%81"
        }}>{`Śūnyatā`}</a></li>
    </ul>
    <h2 {...{
      "id": "additional-avenues",
      "style": {
        "position": "relative"
      }
    }}>{`Additional Avenues`}<a parentName="h2" {...{
        "href": "#additional-avenues",
        "aria-label": "additional avenues permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`While appreciation of the words above and their various traces and sahdes of meaning will help you udnerstand the specific cultural tradition from which this practice emerges, we also include here a series of links by which we intend to reflect and construct together a collage of knowing ways which can root us in a deeper understanding of what meditation is and can be in this time and cultural moment.`}</p>
    <Process mdxType="Process">
      <p><a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=WIoHD4PbUeA"
        }}>{`Meditation and talk by Jack Kornfield`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=4hlmkNJgtwQ"
        }}>{`Laurie Anderson's Buddhism: Art, Meditation, and Death as Adventure`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=vgZMPcrRmio"
        }}>{`Francisco Varela: The Common Source of Living Traditions`}</a>{`. You can find a beautiful dialogue `}<a parentName="p" {...{
          "href": "https://achrafkassioui.com/resources/Francisco%20Varela%20-%20Three%20Gestures%20of%20Becoming%20Aware.pdf"
        }}>{`on being aware here`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=pyF8ayWuHyo"
        }}>{`Mindfulness of Race by Ruth King`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=RZ0YeBIOaFw"
        }}>{`Meditation and Creativity`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=1H2Cgc60UlU"
        }}>{`A more secular perspective on meditation`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.technologyreview.com/2021/08/25/1031432/what-is-mind-brain-body-connection"
        }}>{`A human mind is constructed by a brain in constant conversation, moment by unique moment, with a body and the outside world`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=7H0FKzeuVVs"
        }}>{`Yoga nidra for full resting and relaxation`}</a></p>
    </Process>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      